import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { ModalYesNoDialog } from './ModalYesNoDialog.js';
import {
    DigitalObject,
    DoipClientResponse,
    DoipConstants,
    InDoipSegment,
    AbstractDoipClient
} from '@cnri/doip-client';

export class ObjectMethods {
    private readonly methodSelect: JQuery;
    private readonly methodAttributesDiv: JQuery;
    private readonly methodAttributesEditor: JsonEditorOnline;
    private readonly methodInputDiv: JQuery;
    private readonly methodInputEditor: JsonEditorOnline;
    private readonly fileInput: JQuery<HTMLInputElement>;
    private readonly outputDiv: JQuery;
    private readonly outputEditor: JsonEditorOnline;

    private readonly typeForCall: string;
    private readonly objectId: string;

    private useInputFromFile: boolean = false;

    constructor(
            containerDiv: JQuery,
            objectId: string,
            type: string,
            digitalObject: DigitalObject) {
        this.objectId = objectId;
        if (type === "Schema") {
            this.typeForCall = (digitalObject.attributes.content as { name: string }).name;
        } else {
            this.typeForCall = type;
        }

        const html = $(`
            <div class="object-editor-toolbar col-md-12 pull-right">
                <form id="methodsForm" class="form-horizontal" role="form">
                    <div class="form-group">
                        <label for="methodSelect" class="col-sm-1 control-label">Operation</label>
                        <div class="col-sm-8">
                            <select id="methodSelect" class="form-control"></select>
                        </div>
                        <button id="callButton" class="col-sm-1 btn btn-sm btn-primary">
                            <span>Invoke</span>
                        </button>
                        <button id="clearButton" class="col-sm-1 btn btn-sm btn-primary">
                            <span>Clear</span>
                        </button>
                    </div>
                </form>
            </div>
            <div class="card card-body bg-light" id=ioDiv>
                <div class="form-group col-md-6" id=input>
                    <label for="methodAttributes" style="display: block;">Attributes</label>
                    <div id="methodAttributes" style="height: 200px"></div>
                    <label for="methodInput" style="display: block;">Input</label>
                    <div id="methodInput" style="height: 300px"></div>
                    <div>
                        <input id="methodfileInput" type="file"/>
                    </div>
                </div>
                <div class="form-group col-md-6" id=output>
                    <label for="methodOutput" style="display: block;">Output</label>
                    <div id="methodOutput" style="height: 500px"></div>
                </div>
            </div>
        `);
        containerDiv.append(html);

        const callButton = $('#callButton');
        callButton.on("click", () => this.onCallMethodClick());

        const clearButton = $('#clearButton');
        clearButton.on("click", () => this.onClearClick());

        const methodsForm = $('#methodsForm');
        methodsForm.on("submit", () => {
            return false;
        });

        this.methodSelect = $('#methodSelect');
        this.methodAttributesDiv = $("#methodAttributes");
        this.methodInputDiv = $("#methodInput");
        this.fileInput = $("#methodfileInput");
        this.prettifyThisFileInput(this.fileInput);
        this.outputDiv = $("#methodOutput");

        const methodOpts = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                console.error(err);
            }
        } as JSONEditorOptions;
        this.methodAttributesEditor = new JsonEditorOnline(this.methodAttributesDiv[0], methodOpts, {});
        this.methodInputEditor = new JsonEditorOnline(this.methodInputDiv[0], methodOpts, {});

        const ouptutOpts = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                console.error(err);
            }
        } as JSONEditorOptions;
        this.outputEditor = new JsonEditorOnline(this.outputDiv[0], ouptutOpts);
        this.outputEditor.setText("");
        APP.disableJsonEditorOnline(this.outputEditor);
        this.listOperations().catch(console.error);
    }

    prettifyThisFileInput(input: JQuery<HTMLInputElement>): void {
        if (input.css("opacity") === "0") return;
        input.css("opacity", "0");
        input.css("z-index", "-100");
        input.css("position", "fixed");
        input.css("left", "-10px");
        input.css("height", "1px");
        input.css("width", "1px");
        input.css("margin", "0");
        input.css("padding", "0");
        const textForButton = "Choose input file";
        const button = $(
            '<button class="btn btn-sm btn-primary" type="button"><i class="fa fa-file"></i><span>' +
            textForButton +
            "</span></button>"
        );
        const fileCancelButton = $('<button id="methodFileInputCancelButton" style="display:none" class="btn btn-sm btn-primary" type="button">Cancel</button>');
        const span = $('<p class="helpText">No file chosen</p>');
        const div = $('<div class="hide-with-buttons"/>');
        div.append(button, fileCancelButton, span);
        input.before(div);
        button.off("click").on("click", (event) => {
            event.stopImmediatePropagation();
            input.trigger("click");
        });
        fileCancelButton.on("click", (event) => {
           input.val("");
           input.trigger("change");
        });
        input.on("change", () => {
            if (input[0].files) {
                if (input[0].files.length === 0) {
                    span.text("No file chosen");
                    fileCancelButton.hide();
                    APP.enableJsonEditorOnline(this.methodInputEditor);
                    this.useInputFromFile = false;
                } else {
                    span.text(input[0].files[0].name);
                    fileCancelButton.show();
                    APP.disableJsonEditorOnline(this.methodInputEditor);
                    this.useInputFromFile = true;
                }
            }
        });
    }

    onCallMethodClick(): void {
        const op = this.methodSelect.find(':selected').val() as string;
        if ("0.DOIP/Op.Delete" === op) {
            const dialog = new ModalYesNoDialog(
                'Are you sure you want to delete this object?',
                (() => {
                    this.callMethod();
                }),
                () => {
                  //no-op
                }
            );
            dialog.show();
        } else {
            this.callMethod();
        }
    }

    onClearClick(): void {
        this.outputEditor.setText("");
    }

    async listOperations(): Promise<void> {
        const ops = await APP.listOperations(this.objectId);
        ops.sort();
        for (const op of ops) {
            const option = $(
                '<option value="' + op + '">' + op + "</option>"
            );
            this.methodSelect.append(option);
        }
    }

    callMethod(): void {
        const op = this.methodSelect.find(':selected').val() as string;
        const attributesText: string = this.methodAttributesEditor.getText();
        let attributes = undefined;
        if (attributesText) {
            try {
                attributes = JSON.parse(attributesText);
            } catch (error) {
                APP.notifications.alertError("Attributes is not valid JSON.");
                return;
            }
        }
        if (this.useInputFromFile) {
            const input = this.fileInput?.[0].files?.[0];
            APP.performOperationWithFileInput(
                this.objectId,
                op,
                input,
                attributes,
                (doipResponse: DoipClientResponse) => this.onPerformOperation(doipResponse)
            );
        } else {
            const inputText: string = this.methodInputEditor.getText();
            let input = undefined;
            if (inputText) {
                try {
                    input = JSON.parse(inputText);
                } catch (error) {
                    APP.notifications.alertError("Input is not valid JSON.");
                    return;
                }
            }
            APP.performOperation(
                this.objectId,
                op,
                input,
                attributes,
                (doipResponse: DoipClientResponse) => this.onPerformOperation(doipResponse)
            );
        }

    }

    async onPerformOperation(doipResponse: DoipClientResponse): Promise<void> {
        try {
            const prettyText = await this.getJsonResponse(doipResponse);
            if (prettyText) {
                this.outputEditor.setText(prettyText);
            } else {
                //TODO handle non json responses
            }
            if (doipResponse.getStatus() !== DoipConstants.STATUS_OK) {
                throw await AbstractDoipClient.doipExceptionFromDoipResponse(doipResponse);
            }
        } finally {
            if (doipResponse) {
                await doipResponse.close();
            }
        }
    }

    async getJsonResponse(doipResponse: DoipClientResponse): Promise<string | null> {
        const inDoipMessage = doipResponse.getOutput();
        const firstSegment: InDoipSegment | null = await AbstractDoipClient.getFirstSegment(inDoipMessage);
        if (firstSegment == null) {
            throw new Error("Missing first segment in response");
        }
        if (firstSegment.isJson) {
            const prettyText = JSON.stringify(await firstSegment.json(), null, 2);
            return prettyText;
        } else {
            return null;
        }
    }


    onCallSuccess(response: Response): void {
        response
            .text()
            .then((responseText) => {
                try {
                    const json = JSON.parse(responseText);
                    const prettyText = JSON.stringify(json, null, 2);
                    this.outputEditor.setText(prettyText);
                } catch (error) {
                    this.outputEditor.setText(responseText);
                    console.log(response);
                }
            })
            .catch(console.error);
    }
}
