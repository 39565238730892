import { default as JsonEditorOnline, JSONEditorOptions } from 'jsoneditor';
import { JsonSchema } from 'tv4';
import { DigitalObject, DigitalElement, DoipConstants } from '@cnri/doip-client';

import { JSONEditor } from '../cordra/JSONEditorCordraMods.js';
import { ObjectPreviewUtil } from "../ObjectPreviewUtil.js";
import { RelationshipsGraphComponent } from '../components/RelationshipsGraphComponent.js';
import { UploadProgressBar } from './UploadProgressBar.js';
import { ElementsEditor } from "./ElementsEditor.js";
import { ObjectMethods } from "./ObjectMethods.js";
import { FullDigitalObjectViewer } from "./FullDigitalObjectViewer.js";
import { ObjectVersions } from "./ObjectVersions.js";
import { ObjectAclEditor } from './ObjectAclEditor.js';
import { ObjectConvertUtil } from './ObjectConvertUtil.js';
import { VersionInfo } from "./VersionInfo.js";
import { AccessControlList } from "./AccessControlList.js";

interface ObjectEditorOptions {
    schema: JsonSchema | undefined;
    type: string;
    objectJson: unknown;
    objectId?: string;
    allowEdits: boolean;
    disabled: boolean;
    digitalObject: DigitalObject;
    allowDownloadElements: boolean;
}

export default class ObjectEditor {
    private readonly schema: JsonSchema;
    private readonly type: string;
    private readonly objectJson: unknown;
    private readonly objectId?: string;
    private readonly disabled: boolean;
    private readonly allowEdits: boolean;
    private readonly digitalObject: DigitalObject;
    private readonly allowDownloadElements: boolean;

    private readonly containerDiv: JQuery;
    private readonly editor: typeof JSONEditor;
    private readonly objectEditorDiv: JQuery;
    private readonly editorDiv: JQuery;
    private readonly toolBarDiv: JQuery;
    private readonly tabControls: JQuery;
    private editJsonDiv!: JQuery;
    private jsonEditorOnline!: JsonEditorOnline;
    private readonly aclEditorDiv: JQuery;
    private aclEditor?: ObjectAclEditor;

    private relationshipsGraph?: RelationshipsGraphComponent;
    private readonly relationshipsGraphDiv: JQuery;
    private readonly versionsEditorDiv: JQuery;
    private readonly operationsDiv: JQuery;
    private objectMethods: null | ObjectMethods = null;

    private suffixInput!: JQuery;
    private handleInput!: JQuery;
    private readonly advancedDiv: JQuery;
    private readonly bottomToolBarDiv!: JQuery;
    private saveButtonBottom!: JQuery;
    private revertButtonBottom!: JQuery;
    private deleteButtonBottom!: JQuery;
    private saveButton!: JQuery;
    private readonly elementsEditorDiv: JQuery;
    private readonly elementsEditor!: ElementsEditor;

    private userMetadataDiv!: JQuery;
    private userMetadataLabel!: JQuery;
    private userMetadataExpandIcon!: JQuery;
    private userMetadataEditorDiv!: JQuery;
    private userMetadataEditor!: JsonEditorOnline;

    private readonly bottomProgressDiv!: JQuery;
    private bottomProgressBar!: UploadProgressBar;

    private editJsonLink!: JQuery;
    private editFormLink!: JQuery;
    private viewFormLink!: JQuery;
    private linkToNewTabWithJson!: JQuery;
    private linkToTextAreaWithJson!: JQuery;

    constructor(containerDiv: JQuery, objectEditorOptions: ObjectEditorOptions) {
        this.containerDiv = containerDiv;
        // The editor might modify the schema when expanding $ref so we need to deep clone it here
        this.schema = $.extend(true, {}, objectEditorOptions.schema);
        this.type = objectEditorOptions.type;
        this.objectJson = objectEditorOptions.objectJson;
        this.objectId = objectEditorOptions.objectId;
        this.allowEdits = objectEditorOptions.allowEdits;
        this.disabled = objectEditorOptions.disabled || !this.allowEdits;
        this.digitalObject = objectEditorOptions.digitalObject;
        //this.contentPlusMeta = ObjectConvertUtil.digitalObjectToCordraObject(this.digitalObject)!;
        this.allowDownloadElements = objectEditorOptions.allowDownloadElements;

        const headerRow = $('<div class="row object-header"></div>');
        containerDiv.append(headerRow);

        const objectHeader = $('<div class="heading col-md-12"></div>');
        const objectTitleHeading = $('<h3 class="editorTitle"></h3>');
        objectHeader.append(objectTitleHeading);
        let objectTitleText;
        if (this.objectId != null) {
            objectTitleText = this.getObjectTitleText();
            if (!objectTitleText) {
                objectTitleText = this.objectId;
            }
            const objectIdElement = $('<p></p>');
            objectIdElement.text("Id: " + this.objectId);
            objectHeader.append(objectIdElement);
        } else {
            objectTitleText = "New";
        }
        objectTitleHeading.text(objectTitleText);

        if (!APP.getUiConfig().hideTypeInObjectEditor) {
            const typeText = $("<p></p>");
            typeText.text("Type: " + this.type);
            objectHeader.append(typeText);
        }

        headerRow.append(objectHeader);

        const metadata = this.digitalObject.attributes.metadata as { isVersion?: boolean; versionOf?: string } | undefined;
        if (metadata?.isVersion) {
            const newerVersionText = $(
                "<p>This object was taken as a snapshot of  </p>"
            );
            containerDiv.append(newerVersionText);
            const versionOf = metadata.versionOf!;
            const link = $('<a style="display:inline-block" target="_blank">')
                .attr("href", APP.getBaseUri() + "#objects/" + versionOf)
                .text(versionOf);
            link.attr("data-handle", versionOf);
            link.on("click", (e) => this.onNewerVersionClick(e));
            newerVersionText.append(link);
        }

        if (this.objectId == null) {
            const uiConfig = APP.getUiConfig();
            if (uiConfig.allowUserToSpecifyHandleOnCreate) {
                this.createHandleInput();
            } else if (uiConfig.allowUserToSpecifySuffixOnCreate) {
                this.createSuffixInput();
            }
        }

        const tabPanel = $('<div class="tab">');
        containerDiv.append(tabPanel);

        this.tabControls = $('<ul class="nav nav-tabs"></ul>');
        tabPanel.append(this.tabControls);
        this.createTabs();

        const tabContent = $('<div class="tab-content tabs"></div>');
        tabPanel.append(tabContent);

        this.versionsEditorDiv = $(
            '<div class="versionsEditor col-md-12 tab-pane" id="versionsEditor"></div>'
        );
        tabContent.append(this.versionsEditorDiv);

        this.operationsDiv = $(
            '<div class="objectMethods col-md-12 tab-pane" id="objectOperations"></div>'
        );
        tabContent.append(this.operationsDiv);

        this.aclEditorDiv = $(
            '<div class="aclEditor col-md-12 tab-pane" id="aclEditor"></div>'
        );
        tabContent.append(this.aclEditorDiv);

        this.relationshipsGraphDiv = $(
            '<div class="relationshipsEditor col-md-12 tab-pane" id="relationshipsEditor"></div>'
        );
        tabContent.append(this.relationshipsGraphDiv);

        this.objectEditorDiv = $(
            '<div class="objectEditor col-md-12 tab-pane active" id="objectEditor"></div>'
        );
        tabContent.append(this.objectEditorDiv);

        this.advancedDiv = $(
            '<div class="advancedEditor col-md-12 tab-pane" id="advancedEditor"></div>'
        );
        tabContent.append(this.advancedDiv);

        this.toolBarDiv = $(
            '<div class="object-editor-toolbar col-md-12 pull-right"></div>'
        );
        this.objectEditorDiv.append(this.toolBarDiv);

        this.createToolBar();
        this.createEditJsonDiv();

        this.editorDiv = $('<div class="editor col-md-12 nopadding"></div>');
        this.objectEditorDiv.append(this.editorDiv);

        this.fixPropertyOrder(this.schema);
        const options = {
            theme: "bootstrap3",
            iconlib: "fontawesome4",
            schema: this.schema,
            startval: this.objectJson, //content
            disable_edit_json: true,
            disable_properties: true,
            disable_collapse: true,
            disabled: this.disabled,
            prompt_before_delete: false
        };
        JSONEditor.defaults.options.iconlib = "bootstrap3";
        JSONEditor.defaults.editors.object.options.disable_properties = true;
        JSONEditor.defaults.editors.object.options.disable_edit_json = true;
        JSONEditor.defaults.editors.object.options.disable_collapse = false;

        this.editor = new JSONEditor(this.editorDiv[0], options);
        this.editor.on("change", () => this.onChange());
        if (this.disabled) {
            this.editor.disable();
            this.editorDiv.addClass("hidden-buttons");
        }

        const editorTitle = $("div[data-schemapath=root] > h3 > span:first-child");
        if (editorTitle.text() === "root") {
            editorTitle.parent().hide();
        }

        this.elementsEditorDiv = $('<div class="payloadEditor col-md-12"></div>');
        this.objectEditorDiv.append(this.elementsEditorDiv);

        if (!this.disabled || (this.digitalObject.elements && this.digitalObject.elements.length > 0)) {
            this.elementsEditor = new ElementsEditor(
                this.elementsEditorDiv,
                this.digitalObject.elements!,
                this.disabled,
                this.allowDownloadElements
            );
        } else {
            this.elementsEditorDiv.hide();
        }

        this.buildUserMetadataEditor();

        if (!this.disabled && this.allowEdits) {
            this.bottomToolBarDiv = $(
                '<div class="object-editor-toolbar col-md-offset-6 col-md-6 pull-right"></div>'
            );
            this.objectEditorDiv.append(this.bottomToolBarDiv);
            this.createBottomToolBar();
            this.bottomProgressDiv = $("<div></div>");
            this.objectEditorDiv.append(this.bottomProgressDiv);
        }
    }

    buildUserMetadataEditor(): void {
        const userMetadata = ObjectConvertUtil.getUserMetadataFromDigitalObject(this.digitalObject);
        const hasUserMetadata = Object.keys(userMetadata).length !== 0;
        // if (!this.contentPlusMeta.userMetadata && this.disabled) {
        //     return;
        // }
        if (!hasUserMetadata && this.disabled) {
            return;
        }
        this.userMetadataDiv = $('<div id=userMetadata class="userMetadataEditor col-md-12"></div>');
        this.objectEditorDiv.append(this.userMetadataDiv);
        this.userMetadataLabel = $('<label>userMetadata </label>');
        this.userMetadataExpandIcon = $('<i class="fa fa-chevron-down"></i>');
        this.userMetadataLabel.append(this.userMetadataExpandIcon);
        this.userMetadataDiv.append(this.userMetadataLabel);


        this.userMetadataEditorDiv = $('<div style="height: 300px; display:block; width:100%;"></div>');
        this.userMetadataEditorDiv.hide();
        this.userMetadataDiv.append(this.userMetadataEditorDiv);

        this.userMetadataLabel.on('click', () => {
            if (this.userMetadataEditorDiv.is(":hidden")) {
                this.userMetadataExpandIcon.removeClass("fa-chevron-down");
                this.userMetadataExpandIcon.addClass("fa-chevron-up");
                this.userMetadataEditorDiv.show();
            } else {
                this.userMetadataExpandIcon.removeClass("fa-chevron-up");
                this.userMetadataExpandIcon.addClass("fa-chevron-down");
                this.userMetadataEditorDiv.hide();
            }
        });

        const userMetadataOptions = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                APP.onErrorResponse(err);
            }
        } as JSONEditorOptions;
        this.userMetadataEditor = new JsonEditorOnline(this.userMetadataEditorDiv[0], userMetadataOptions);
        // if (this.contentPlusMeta.userMetadata) {
        //     this.userMetadataEditor.set(this.contentPlusMeta.userMetadata);
        if (hasUserMetadata) {
            this.userMetadataEditor.set(userMetadata);
        } else {
            this.userMetadataEditor.setText("");
        }
        if (this.disabled) {
            APP.disableJsonEditorOnline(this.userMetadataEditor);
        }
    }

    fixPropertyOrder(schemaToFix: JsonSchema): void {
        if (!this.isObject(schemaToFix)) return;
        for (const key in schemaToFix) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.fixPropertyOrder(schemaToFix[key]);
        }
        if (this.isObject(schemaToFix.properties)) {
            let count = 1;
            for (const prop in schemaToFix.properties) {
                const propSchema = schemaToFix.properties[prop];
                if (this.isObject(propSchema)) {
                    propSchema.propertyOrder = count;
                    count++;
                }
            }
        }
    }

    isObject(obj: unknown): boolean {
        return !!obj && typeof obj === "object";
    }

    createTabs(): void {
        if (this.objectId != null) {
            const objectTab = $(
                '<li class="active"><a data-toggle="tab" href="#objectEditor">Object</a></li>'
            );
            this.tabControls.append(objectTab);
            const aclTab = $(
                '<li class=""><a data-toggle="tab" href="#aclEditor">ACL</a></li>'
            );
            this.tabControls.append(aclTab);
            aclTab.on('click', () => this.onShowACL());

            const versionsTab = $(
                '<li class=""><a data-toggle="tab" href="#versionsEditor">Versions</a></li>'
            );
            this.tabControls.append(versionsTab);
            versionsTab.on('click', () => this.onShowVersions());

            const operationsTab = $(
                '<li class=""><a data-toggle="tab" href="#objectOperations">Operations</a></li>'
            );
            this.tabControls.append(operationsTab);
            operationsTab.on('click', () => this.onShowOperations());

            const advancedTab = $(
                '<li class=""><a data-toggle="tab" href="#advancedEditor">DO View / Details</a></li>'
            );
            this.tabControls.append(advancedTab);
            advancedTab.on('click', () => this.onShowAdvanced());

            const relativesTab = $(
                '<li class=""><a data-toggle="tab" href="#relationshipsEditor">Relatives</a></li>'
            );
            this.tabControls.append(relativesTab);
            relativesTab.on('click', () => this.onShowRelationships());
        }
    }

    createToolBar(): void {
        if (this.disabled) {
            if (this.allowEdits) {
                const editButton = $(
                    '<button class="btn btn-sm btn-primary" data-loading-text="Editing..."><i class="fa fa-edit"></i></button>'
                );
                this.toolBarDiv.append(editButton);
                editButton.on("click", () => APP.viewOrEditCurrentObject(false));

                const editButtonSpan = $("<span><span>");
                editButton.append(editButtonSpan);
                editButtonSpan.text("Edit");
            }
            const objectBreadCrumbs = $('<nav aria-label="breadcrumb"></nav>');
            this.toolBarDiv.append(objectBreadCrumbs);
            const breadCrumbOl = $('<ol class="breadcrumb"></ol>');
            objectBreadCrumbs.append(breadCrumbOl);

            this.viewFormLink = $(
                '<li class="breadcrumb-item nodivider" aria-current="page" style="display:none"></li>'
            );
            const viewLink = $('<a><i class="fa fa-list-alt"></i>View Form</a>');
            this.viewFormLink.append(viewLink);
            viewLink.on("click", () => this.onViewForm());
            breadCrumbOl.append(this.viewFormLink);

            const jsonForm = $(
                '<form style="display:none" method="POST" target="_blank"/>'
            );
            const jsonLinkUri = APP.getBaseUri() + "doip/?t=" + this.objectId + "&o=" + DoipConstants.OP_RETRIEVE;
            jsonForm.attr("action", jsonLinkUri);
            const accessTokenInput = $('<input type="hidden" name="access_token"/>');
            APP.getAccessToken()
                .then((accessToken: string | undefined) => {
                    if (accessToken) accessTokenInput.val(accessToken);
                })
                .catch(console.error);
            jsonForm.append(accessTokenInput);

            this.linkToTextAreaWithJson = $(
                '<li class="breadcrumb-item" aria-current="page"></li>'
            );
            breadCrumbOl.append(this.linkToTextAreaWithJson);
            const anchorToTextAreaWithJson = $('<a><i class="fa fa-stream"></i>View JSON</a>');
            this.linkToTextAreaWithJson.append(anchorToTextAreaWithJson);
            this.linkToTextAreaWithJson.on("click", () => this.onViewJson());
            breadCrumbOl.append(jsonForm);

            this.linkToNewTabWithJson = $(
                '<li class="breadcrumb-item" aria-current="page"></li>'
            );
            breadCrumbOl.append(this.linkToNewTabWithJson);
            const anchorToNewTabWithJson = $('<a><i class="fa fa-external-link-alt"></i>JSON</a>');
            this.linkToNewTabWithJson.append(anchorToNewTabWithJson);
            this.linkToNewTabWithJson.on("click", (event) => {
                event.preventDefault();
                jsonForm.trigger("submit");
            });
        } else {
            if (this.objectId != null) {
                const deleteButton = $(
                    '<button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>'
                );
                this.toolBarDiv.append(deleteButton);
                deleteButton.on("click", () => this.onDelete());

                const deleteButtonSpan = $("<span><span>");
                deleteButton.append(deleteButtonSpan);
                deleteButtonSpan.text("Delete");

                const revertButton = $(
                    '<button class="btn btn-sm btn-warning"><i class="fa fa-undo"></i></button>'
                );
                this.toolBarDiv.append(revertButton);
                revertButton.on("click", () => this.onRevert());

                const revertButtonSpan = $("<span><span>");
                revertButton.append(revertButtonSpan);
                revertButtonSpan.text("Revert");
            }

            this.saveButton = $(
                '<button class="btn btn-sm btn-success" data-loading-text="Saving..."><i class="fa fa-save"></i></button>'
            );
            this.toolBarDiv.append(this.saveButton);
            this.saveButton.on("click", () => this.save());

            const saveButtonSpan = $("<span><span>");
            this.saveButton.append(saveButtonSpan);
            saveButtonSpan.text("Save");

            const objectBreadCrumbs = $('<nav aria-label="breadcrumb"></nav>');
            this.toolBarDiv.append(objectBreadCrumbs);
            const breadCrumbOl = $('<ol class="breadcrumb"></ol>');
            objectBreadCrumbs.append(breadCrumbOl);

            this.editFormLink = $(
                '<li class="breadcrumb-item" aria-current="page" style="display:none"></li>'
            );
            const editLink = $('<a><i class="fa fa-edit"></i>Edit as Form</a>');
            this.editFormLink.append(editLink);
            editLink.on("click", () => this.onEditForm());
            breadCrumbOl.append(this.editFormLink);

            this.editJsonLink = $(
                '<li class="breadcrumb-item nodivider" aria-current="page"></li>'
            );
            const editJLink = $('<a><i class="fa fa-stream"></i>Edit as JSON</a>');
            this.editJsonLink.append(editJLink);
            editJLink.on("click", () => this.onEditJson());
            breadCrumbOl.append(this.editJsonLink);
        }
    }

    createBottomToolBar(): void {
        // Revert and delete button should not be shown if creating a new object
        if (this.objectId != null) {
            this.deleteButtonBottom = $(
                '<button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>'
            );
            this.bottomToolBarDiv.append(this.deleteButtonBottom);
            this.deleteButtonBottom.on("click", () => this.onDelete());

            const deleteButtonBottomSpan = $("<span><span>");
            this.deleteButtonBottom.append(deleteButtonBottomSpan);
            deleteButtonBottomSpan.text("Delete");

            this.revertButtonBottom = $(
                '<button class="btn btn-sm btn-warning"><i class="fa fa-undo"></i></button>'
            );
            this.bottomToolBarDiv.append(this.revertButtonBottom);
            this.revertButtonBottom.on("click", () => this.onRevert());

            const revertButtonBottomSpan = $("<span><span>");
            this.revertButtonBottom.append(revertButtonBottomSpan);
            revertButtonBottomSpan.text("Revert");
        }

        this.saveButtonBottom = $(
            '<button class="btn btn-sm btn-success" data-loading-text="Saving..."><i class="fa fa-save"></i></button>'
        );
        this.bottomToolBarDiv.append(this.saveButtonBottom);
        this.saveButtonBottom.on("click", () => this.save());

        const saveButtonBottomSpan = $("<span><span>");
        this.saveButtonBottom.append(saveButtonBottomSpan);
        saveButtonBottomSpan.text("Save");
    }

    onNewerVersionClick(e: JQuery.ClickEvent): void {
        e.preventDefault();
        const link = $(e.target);
        const handle = link.attr("data-handle") as string;
        APP.resolveHandle(handle);
    }

    createHandleInput(): void {
        const handleDiv = $('<div class="handleEditor"></div>');
        this.containerDiv.append(handleDiv);
        const handleLabel = $('<label class="control-label">Handle</label>');
        handleDiv.append(handleLabel);

        const suffixAndPrefixDiv = $("<div></div>");
        handleDiv.append(suffixAndPrefixDiv);

        this.handleInput = $(
            '<input type="text" style="display: inline-block; width: auto" class="form-control" placeholder="Handle (optional)" />'
        );
        suffixAndPrefixDiv.append(this.handleInput);
    }

    createSuffixInput(): void {
        const handleDiv = $('<div class="handleEditor"></div>');
        this.containerDiv.append(handleDiv);
        const handleLabel = $('<label class="control-label">Handle</label>');
        handleDiv.append(handleLabel);

        const suffixAndPrefixDiv = $("<div></div>");
        handleDiv.append(suffixAndPrefixDiv);

        const prefixLabel = $('<span style="display: inline-block"></span>');
        prefixLabel.text(APP.getPrefix() + "/");
        prefixLabel.append("&nbsp;");
        suffixAndPrefixDiv.append(prefixLabel);

        this.suffixInput = $(
            '<input type="text" style="display: inline-block; width: auto" class="form-control" placeholder="Suffix (optional)" />'
        );
        suffixAndPrefixDiv.append(this.suffixInput);
    }

    onShowACL(): void {
        APP.getAclForCurrentObject((resp: AccessControlList) => this.onGotAclSuccess(resp));
    }

    onShowVersions(): void {
        APP.getVersionsFor(this.objectId, (resp: VersionInfo[]) => this.onGotVersionsSuccess(resp));
    }

    onShowOperations(): void {
        if (!this.objectId) return;
        if (!this.objectMethods) {
            this.operationsDiv.empty();
            this.objectMethods = new ObjectMethods(this.operationsDiv, this.objectId, this.type, this.digitalObject);
        }
    }

    onShowAdvanced(): void {
        this.advancedDiv.empty();
        new FullDigitalObjectViewer(this.advancedDiv, this.digitalObject);
    }

    onShowRelationships(): void {
        this.showRelationshipsGraph();
    }

    onGotVersionsSuccess(versions: VersionInfo[]): void {
        if (!this.objectId) return;
        this.versionsEditorDiv.empty();
        new ObjectVersions(
            this.versionsEditorDiv,
            versions,
            this.objectId,
            this.allowEdits
        );
    }

    onGotAclSuccess(res: AccessControlList): void {
        if (!this.objectId) return;
        if (this.aclEditor) this.aclEditor.destroy();
        this.aclEditorDiv.empty();
        const uiConfig = APP.getUiConfig();
        this.aclEditor = new ObjectAclEditor(
            this.aclEditorDiv,
            res,
            uiConfig.aclUiSearchTypes as string[],
            this.allowEdits
        );
    }

    createEditJsonDiv(): void {
        this.editJsonDiv = $('<div class="editJsonEditor col-md-12" style="display:none;"></div>');
        this.editJsonDiv.attr("data-view", "");
        this.objectEditorDiv.append(this.editJsonDiv);

        const editJsonTextDiv = $('<div style="height: 500px; display:block; width:100%;"></div>');
        this.editJsonDiv.append(editJsonTextDiv);

        const container = editJsonTextDiv[0];
        const options = {
            ace,
            theme: "ace/theme/textmate",
            mode: "code",
            modes: ["code", "tree"], // allowed modes
            onError(err: Error) {
                APP.onErrorResponse(err);
            }
        } as JSONEditorOptions;
        this.jsonEditorOnline = new JsonEditorOnline(container, options);
    }

    showRelationshipsGraph(): void {
        if (!this.objectId) return;
        this.relationshipsGraph = new RelationshipsGraphComponent(this.relationshipsGraphDiv[0], this.objectId);
    }

    hideRelationshipsGraph(): void {
        this.relationshipsGraph?.destroy();
        this.relationshipsGraphDiv.empty();
    }

    onCloseClick(): void {
        APP.clearFragment();
    }

    destroy(): void {
        if (this.aclEditor) this.aclEditor.destroy();
        this.hideRelationshipsGraph();
        this.editor.destroy();
        this.jsonEditorOnline.destroy();
    }

    getObjectTitleText(): string | undefined {
        const previewData = ObjectPreviewUtil.getPreviewData(this.digitalObject);
        let objectTitleText;
        for (const jsonPointer in previewData) {
            const thisPreviewData = previewData[jsonPointer];
            const prettifiedPreviewData = ObjectPreviewUtil.prettifyPreviewJson(thisPreviewData.previewJson);
            if (!prettifiedPreviewData) continue;
            if (thisPreviewData.isPrimary) {
                objectTitleText = prettifiedPreviewData;
                break;
            }
        }
        return objectTitleText;
    }

    onChange(): void {
        this.fixButtonGroupCss();
    }

    fixButtonGroupCss(): void {
        $(".cordra-round-left").removeClass("cordra-round-left");
        $(".cordra-round-right").removeClass("cordra-round-right");
        this.editorDiv.find(".btn-group").each((_, div) => {
            const $div = $(div);
            const firstChild = $div.children().first();
            if (!firstChild.is(":visible")) {
                const firstDisplayedChild = $div.children(":visible").first();
                firstDisplayedChild.addClass("cordra-round-left");
            }
            const lastChild = $div.children().last();
            if (!lastChild.is(":visible")) {
                const lastDisplayedChild = $div.children(":visible").last();
                lastDisplayedChild.addClass("cordra-round-right");
            }
        });
    }

    getAllowDownloadElements(): boolean {
        return this.allowDownloadElements;
    }

    getAllowEdits(): boolean {
        return this.allowEdits;
    }

    getJsonFromEditor(): unknown {
        return this.editor.getValue();
    }

    getDigitalObject(): DigitalObject {
        //TODO I am not sure about this. Is anything lost? Double checkd
        const digitalObjectCopy = $.extend(true, {}, this.digitalObject);
        digitalObjectCopy.attributes.content = this.getJsonFromEditor();
        return digitalObjectCopy;

        // const contentPlusMetaCopy = $.extend(true, {}, this.contentPlusMeta);
        // contentPlusMetaCopy.content = this.getJsonFromEditor();
        // const digitalObject: DigitalObject = ObjectConvertUtil.cordraObjectToDigitalObject(contentPlusMetaCopy)!;
        // return digitalObject;
    }

    getType(): string {
        return this.type;
    }

    onEditForm(): void {
        const jsonObject = this.jsonEditorOnline.get();
        this.editor.setValue(jsonObject);
        this.editJsonDiv.attr("data-view", "FORM");
        APP.viewOrEditCurrentObject(false);
    }

    onEditJson(): void {
        const jsonObject = this.editor.getValue();
        this.jsonEditorOnline.set(jsonObject);
        APP.enableJsonEditorOnline(this.jsonEditorOnline);
        this.editJsonDiv.attr("data-view", "JSON");
        this.editJsonLink.hide();
        this.editFormLink.show();
        this.editorDiv.hide();
        this.elementsEditorDiv.hide();
        this.editJsonDiv.show();
    }

    onViewForm(): void {
        const jsonObject = this.jsonEditorOnline.get();
        this.editor.setValue(jsonObject);
        this.editJsonDiv.attr("data-view", "FORM");
        APP.viewOrEditCurrentObject(true);
    }

    onViewJson(): void {
        const jsonObject = this.editor.getValue();
        this.jsonEditorOnline.set(jsonObject);
        this.editJsonDiv.attr("data-view", "JSON");
        APP.disableJsonEditorOnline(this.jsonEditorOnline);
        this.linkToTextAreaWithJson.hide();
        this.viewFormLink.show();
        this.editorDiv.hide();
        this.elementsEditorDiv.hide();
        this.editJsonDiv.show();
    }

    save(): void {
        let digitalObject: DigitalObject;
        try {
            digitalObject = this.buildDigitalObject();
        } catch (err) {
            APP.onErrorResponse(err);
            return;
        }
        this.saveButtonBottom
            .children(":first-child")
            .replaceWith('<i class="fa fa-spinner fa-spin"></i>');
        this.saveButtonBottom
            .children(":nth-child(2)")
            .replaceWith("<span>Loading</span>");
        this.saveButton
            .children(":first-child")
            .replaceWith('<i class="fa fa-spinner fa-spin"></i>');
        this.saveButton.children(":nth-child(2)").replaceWith("<span>Loading</span>");
        this.bottomProgressDiv.empty();
        this.bottomProgressBar = new UploadProgressBar(this.bottomProgressDiv);

        if (!this.objectId) {
            let handle;
            let suffix;
            if (APP.getUiConfig().allowUserToSpecifyHandleOnCreate) {
                handle = this.handleInput.val() as string;
                if (handle === "") {
                    handle = undefined;
                }
            } else if (APP.getUiConfig().allowUserToSpecifySuffixOnCreate) {
                suffix = this.suffixInput.val() as string;
                if (suffix === "") {
                    suffix = undefined;
                }
            }
            if (handle) digitalObject.id = handle;
            digitalObject.type = this.type;
            APP.createObject(
                digitalObject,
                suffix,
                () => this.onSaveError(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - TODO figure out cordra client fetch vs node-fetch incompatibility
                (e: ProgressEvent) => this.progressCallback(e)
            );
        } else {
            digitalObject.id = this.objectId;
            const elementsToDelete: string[] | undefined = this.elementsEditor.getElementsToDelete();
            APP.saveObject(
                digitalObject,
                elementsToDelete,
                () => this.onSaveError(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - TODO figure out cordra client fetch vs node-fetch incompatibility
                (e: ProgressEvent) => this.progressCallback(e)
            );
        }
    }

    progressCallback(event: ProgressEvent): void {
        //TODO cannot setStatus on progress with doip as we don't know the total
        return;
        const fractionComplete = event.loaded / event.total;
        const percentComplete = Math.floor(fractionComplete * 100);
        this.bottomProgressBar.setStatus(percentComplete, event.loaded, event.total);
    }

    onSaveError(): void {
        this.saveButtonBottom
            .children(":first-child")
            .replaceWith('<i class="fa fa-save"></i>');
        this.saveButtonBottom
            .children(":nth-child(2)")
            .replaceWith("<span>Save</span>");
        this.saveButton
            .children(":first-child")
            .replaceWith('<i class="fa fa-save"></i>');
        this.saveButton.children(":nth-child(2)").replaceWith("<span>Save</span>");
        this.bottomProgressDiv.empty();
    }

    onDelete(): void {
        APP.deleteObject(this.objectId);
    }

    onRevert(): void {
        APP.resolveHandle(this.objectId);
    }

    buildDigitalObject(): DigitalObject {
        // determine if we need to consider changes from JSON editor
        const isJSONEditorInView = this.editJsonDiv.attr("data-view") === "JSON";
        let content;
        if (isJSONEditorInView) {
            content = this.jsonEditorOnline.get();
            this.editor.setValue(content);
        } else {
            content = this.getJsonFromEditor();
        }

        const digitalObject: DigitalObject = {
            attributes: {
                content
            }
        };
        const elementInfo: DigitalElement[] = this.elementsEditor.getElements();
        if (elementInfo && elementInfo.length > 0) {
            digitalObject.elements = elementInfo;
        }

        const existingUserMetadata: {} = ObjectConvertUtil.getUserMetadataFromDigitalObject(this.digitalObject);
        const hasExistingUserMetadata = Object.keys(existingUserMetadata).length !== 0;

        const userMetadataText = this.userMetadataEditor.getText() as string;
        let userMetadata;
        if (userMetadataText !== "") {
            try {
                userMetadata = JSON.parse(userMetadataText);
            } catch (err) {
                throw "Error parsing userMetadata: " + err;
            }
        } else if (hasExistingUserMetadata) {
            userMetadata = {};
        }

        ObjectConvertUtil.setUserMetadataOnDigitalObject(userMetadata, digitalObject);
        return digitalObject;
    }
}
